( function( $, window, document, undefined ) {

	//var navigation = '';
	// Check JavaScript is available.
	//if ( element.classList.contains( 'js' ) ) {
	//}

	// Add `.is-closed` modifier to navigation on page load.
	$( document ).ready(function() {
		$( '.c-menu' ).toggleClass( 'is-active' );
	});

	$( document ).ready(function() {

		$( '.js-navigation-toggle' ).click(function(event){
			event.preventDefault();

			if ( $( this ).parent( 'li' ).hasClass( 'tapped-once' ) ) {
				$( this ).parent( 'li' ).removeClass( 'tapped-once' );
			} else {
				$( this ).parents( 'li' ).addClass( 'tapped-once' );
			}

		});

		$( '.js-menu-navigation-primary, [data-js="site-menu-close"]' ).click(function(event) {
			$( '.c-menu' ).toggleClass( 'is-active' );
			$( 'body' ).toggleClass( 'has-menu' );
			event.preventDefault();
		});
	});

})( jQuery, window, document );
